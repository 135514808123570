<template>
	<div class="produits">
		<DataTable title="Produits" type="products" :company="userInfo.company" canDelete alternativeImg :headers="$tableHeaders.products" :items="products" :itemKey="customerEntry.id" link="/record/product" />
	</div>
</template>


<style>
	#blurMe {
		filter: blur(10px);
		-webkit-mask-image: -webkit-gradient(linear,
				left top,
				left bottom,
				from(rgba(0, 0, 0, 1)),
				to(rgba(0, 0, 0, 0)));
	}

	#lateral {
		position: fixed;
		top: 15px;
		right: 15px;
		margin: 0 0 16px 16px;
	}

	#create .v-speed-dial {
		position: absolute;
	}

	#create .v-btn--floating {
		position: relative;
	}
</style>


<script>
	import DataTable from '@/components/modules/#global/DataTable';

	export default {
		props: {
			'id': {
				type: String
			}
		},
		components: {
			DataTable,

		},
		data() {
			return {
				loader: null,
				loading: false,
				dialogDeleteItem: false,
				date: null,
				menu: false,
				expanded: [],
				singleExpand: true,
				dialog: false,
				search: '',
				attrs: '',
				on: '',

				viewCustom: false,
				modifiedCustom: "",

				products: [],

				custom: false,

				customerEntry: this.$models.user,
				userInfo: this.$models.user
			};
		},

		watch: {
			loader() {
				const l = this.loader;
				this[l] = !this[l];

				setTimeout(() => (this[l] = false), 3000);

				this.loader = null;
			},
		},

		methods: {
			openDeleteDialog(itemtt) {
				this.dialogDeleteItem = true,
					this.identifiant = itemtt;
			},

			deleteCustomer(idCustom) {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection('products').doc(idCustom), "Ce produit a été supprimé !");
				this.dialogDeleteItem = false;
			},

			getColor(status) {
				return this.$cssGlobal.getStatus(status)
			},

			preModify(id) {
				this.$db.collection("companies").doc(this.userInfo.company).collection("products").doc(id).onSnapshot(doc => {
					this.customerEntry = doc.data();

					if (this.customerEntry.company) {
						this.$db.collection("companies").doc(this.customerEntry.company).onSnapshot(doc => {
							this.company = doc.data();
						});
					}
				});

				this.modifiedCustom = id;

				this.viewCustom = true;

				this.dialog = true;
			}
		},

		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();

				this.$db.collection("companies").doc(this.userInfo.company).collection("products").onSnapshot((res) => {
					this.products = [];

					var imgLink;

					res.forEach((document) => {
						imgLink = document.data().pictures[0].link;

						this.products.push({
							...document.data(),
							'id': document.id,
							'imgLink': imgLink
						});
					});

				});
			});
		}
	};
</script>
