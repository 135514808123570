<template>
	<v-flex>
		<v-dialog :value="value" @input="$emit('input')" fullscreen hide-overlay transition="dialog-bottom-transition">

			<v-card>
				<v-card-title>
					<span class="headline">Ajouter un Produit</span>
				</v-card-title>

				<v-container>
					<v-form class="form">
						<span class="headline mt-10">Informations Produit</span>
						<v-row class="mb-5">
							<v-col>
								<v-card class="pa-5" outlined tile>
									<v-row>
										<v-col cols="12" sm="6">
											<span class="headline mt-5 mb-1">Produit</span>
											<v-text-field label="Nom" hint="Nom du produit" v-model="productEntry.name"></v-text-field>
											<v-text-field label="Prix" hint="Prix du produit" v-model="productEntry.value" type="number">
											</v-text-field>
											<v-text-field label="Référence" hint="Numéro de référence du produit" v-model="productEntry.reference">
											</v-text-field>
											<v-select v-model="productEntry.status" :items="this.$listUtils.productStatus" label="Statut du produit" required></v-select>
										</v-col>

										<v-col cols="12" sm="6">
											<span class="headline mt-5 mb-1">Images</span>
											<v-row>
												<v-col cols="12" sm="12">
													<v-row>
														<v-col cols="9">
															<div v-for="picture in productEntry.pictures" :key="picture.key">
																<ImageUpload v-if="userInfo.company" :companyID="userInfo.company" type="product" :label="`Image n°${picture.key + 1}`" @newdata="productEntry.pictures[picture.key].link=$event"></ImageUpload>
															</div>
														</v-col>
														<v-col cols="3">
															<v-list-item v-for="picture in productEntry.pictures" :key="picture.key">
																<v-list-item-avatar tile width="250px" height="50px">
																	<v-img contain :src="$functions.getImgLink(picture.link, 200)"></v-img>
																</v-list-item-avatar>
															</v-list-item>
														</v-col>
													</v-row>
												</v-col>
											</v-row>
										</v-col>

										<v-col cols="12">
											<v-divider></v-divider>
										</v-col>

										<v-col cols="12" sm="12">
											<v-row>
												<v-col>
													<h4 class="subtitle-1">Vous voulez générer votre produit par l'IA ?</h4>
													<h5 class="subtitle-2">Décrivez votre produit dans la description courte comme si vous l'expliquiez à des investisseurs.</h5>
												</v-col>
												<v-col cols="auto">
													<v-btn :loading="AIGeneration" @click="writeMeAThing(productEntry.shortDesc)" color="primary" class="neon float-right">Générer</v-btn>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="12" md="12">
													<span class="mt-5 mb-1">Description courte</span>
													<vue-editor v-model="productEntry.shortDesc" />
												</v-col>
											</v-row>
										</v-col>

										<v-col cols="12" sm="12">
											<v-row>
												<v-col cols="12" md="12">
													<span class="mt-5 mb-1">Description Longue</span>
													<vue-editor v-model="productEntry.longDesc" />
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-card>
							</v-col>
						</v-row>
					</v-form>

					<!-- DEBUT APERÇUS -->

					<div>
						<span class="headline mt-10">Aperçu</span>
						<v-card>
							<v-row align-content="start">
								<v-col cols="12" sm="5">
									<v-row align-content="start">
										<v-col cols="1">
											<v-list-item v-for="picture in productEntry.pictures" :key="picture.key">
												<v-list-item-avatar tile>
													<v-btn @click="avatar = picture.link">
														<v-img max-width="50px" contain :src="$functions.getImgLink(picture.link, 50)"></v-img>
													</v-btn>
												</v-list-item-avatar>
											</v-list-item>
										</v-col>
										<v-col cols="11">
											<v-img :src="$functions.getImgLink(avatar, 200)" lazy-src="avatar" min-height="100" max-height="250" contain>
												<template v-slot:placeholder>
													<v-row class="fill-height ma-0" align="center" justify="center">
														<v-progress-circular indeterminate></v-progress-circular>
													</v-row>
												</template>
											</v-img>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="7" class="px-5 py-0">
									<h1>{{productEntry.name}}</h1>
									<h2>{{productEntry.value}}€</h2>
									<div v-html="productEntry.shortDesc"></div>
								</v-col>
							</v-row>
							<v-col cols="12" class="px-5 py-0">
								<h1>Description Produit</h1>
								<div v-html="productEntry.longDesc"></div>
							</v-col>
						</v-card>
					</div>

				</v-container>

				<v-btn id="lateral" fab small outlined color="primary" @click.native="$emit('input', false)">
					<v-icon>mdi-chevron-down</v-icon>
				</v-btn>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="ma-2 mr-10" outlined color="success" @click.native="$emit('input', false)" @click="submit">Ajouter</v-btn>
				</v-card-actions>

			</v-card>
		</v-dialog>
	</v-flex>

</template>


<script>
	import {
		VueEditor
	} from "vue2-editor";
	import ImageUpload from '@/components/graphics/ImageUpload';


	export default {
		props: {
			'value': {},
			'id': {
				type: String
			},
		},
		components: {
			VueEditor,
			ImageUpload
		},
		data() {
			return {
				userInfo: this.$models.user,

				dialog: false,

				avatar: '',

				productEntry: this.$models.product,

				AIGeneration: false

			};
		},
		methods: {
			async writeMeAThing(prompt) {
				this.AIGeneration = true;

				await this.$ai.writeMeAThing({
					company: this.userInfo.company,
					id: this.userInfo.id
				},
				`Reformule ce texte : ${prompt}`).then((value) => {
					if(!value) return this.$store.dispatch('setSnackbar', {
						text: "Votre quota d'utilisation de l'IA est épuisé.",
						color: "error"
					});
					this.productEntry.shortDesc = String(value.data.choices[0].text).trim();
				});

				await this.$ai.writeMeAThing({
					company: this.userInfo.company,
					id: this.userInfo.id
				},
				`Développe ce texte : ${this.productEntry.shortDesc}`,
				-1).then((value) => {
					if(!value) return this.$store.dispatch('setSnackbar', {
						text: "Votre quota d'utilisation de l'IA est épuisé.",
						color: "error"
					});
					this.productEntry.longDesc = String(value.data.choices[0].text).trim();
				});

				this.AIGeneration = false;
			},
			resetFields() {
				this.productEntry = {
					'name': '',
					'value': '',
					'reference': '',

					'shortDesc': '',
					'longDesc': '',

					'pictures': [
						{
							'key': 0,
							'link': ''
						},
						{
							'key': 1,
							'link': ''
						},
						{
							'key': 2,
							'link': ''
						},
						{
							'key': 3,
							'link': ''
						},
					]
				}
			},

			submit() {
				const object = {
					...this.productEntry,

					'status': this.$listUtils.productStatus.indexOf(this.productEntry.status),

					'belongTo': this.userInfo.company,
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("products").add(object).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `Le produit ${object.name} a été ajouté !`,
					});
				});

				this.resetFields();

				this.$emit('input', false)
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};
			});
		}
	};
</script>
