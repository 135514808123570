<template>
	<div class="products">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />

		<v-container>
			<v-card class="glassmorphism pa-4 pt-0 mx-auto" max-width="1280" color="transparent">
				<ButtonTrigger @click.native="dialog = !dialog" title="Produits" content="Ajouter un Produit" whiteText />
				<Products class="maxWidth mx-auto" />
			</v-card>
		</v-container>
		<ProductDialog v-model="dialog" />
	</div>
</template>

<script>
	import Products from '@/components/modules/@product/ProductsTable';
	import ButtonTrigger from '@/components/graphics/buttonTrigger'
	import ProductDialog from '@/components/modules/@product/ProductDialog';
	import Head from '@/components/graphics/head';

	export default {
		head: {
			title: {
				inner: "Produits"
			}
		},
		components: {
			Head,
			ButtonTrigger,
			Products,
			ProductDialog
		},
		data() {
			return {
				dialog: false,
				Head: {

					Title: 'Produits',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "10vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},
			};
		},
	}

</script>
